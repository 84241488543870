<template>
  <div class="home">
    <div class="container">
      <v-header :menu="true" />
    </div>
    <div class="section dt12 db15 mt7 mb10">
      <div class="container">
        <div class="row">
          <div class="d-10 d-center">
            <h1>
              Единое пространство для работы с&nbsp;КЭДО
              и&nbsp;взаимодей&shy;ствия сотрудников
            </h1>
          </div>
          <div class="d-8 d-center dt7 mt6">
            <p class="leading db7 mb6">
              Создание и&nbsp;разработка внутреннего корпоративного портала
            </p>
            <a href="#" class="button m-center" @click.prevent="showModal">
              Отправить запрос
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="section db15 mb10">
      <div class="container">
        <div class="row middle">
          <div class="d-6 d-offset-1 d-hide mb4">
            <img src="@/assets/pages/corporate-portal/pic-01.svg"
              data-copyright-source="https://storyset.com/illustration/about-us-page/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-6" v-onscroll>
            <h2>Зачем нужны корпоративные инструменты</h2>
            <p class="md">
              Без внутреннего корпоративного портала сложно объединить команды
              и&nbsp;отдельных сотрудников, особенно на&nbsp;удаленке. Если нет
              общего пространства, полезная информация теряется в&nbsp;чатах,
              а&nbsp;без мобильного приложения не&nbsp;подписать документы, если
              сотрудник в&nbsp;командировке.
            </p>
            <p class="md">
              Мы&nbsp;создаём корпоративные мобильные и&nbsp;веб-порталы для
              работы с&nbsp;кадровыми документами и&nbsp;взаимодействия
              сотрудников.
            </p>
            <p class="md">
              Веб-портал помогает наладить коммуникации, обмениваться опытом
              и&nbsp;формировать систему мотивации. Такое решение можно внедрить
              на&nbsp;базе Битрикс. Мобильное приложение поможет оставаться
              на&nbsp;связи, согласовывать и&nbsp;подписывать документы.
            </p>
          </div>
          <div class="d-5 d-offset-1 m-hide" v-onscroll>
            <img src="@/assets/pages/corporate-portal/pic-01.svg"
              data-copyright-source="https://storyset.com/illustration/about-us-page/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section section-dark v15 mt7 mb7" v-onscroll>
      <div class="container">
        <div class="row">
          <div class="d-12 dt10 db7 mt7 d-center">
            <h2>Этапы работ</h2>
          </div>
        </div>
        <div class="row">
          <div class="d-3 db11 mt6" v-onscroll>
            <div class="card">
              <p class="card-title">1</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Бизнес-анализ</p>
              <p class="card-text">
                Проанализируем ваши процессы. Найдём слабые и&nbsp;сильные
                места. Предложим лучшее решение.
              </p>
            </div>
          </div>
          <div class="d-3 db11 mt6" v-onscroll="{ delay: 250 }">
            <div class="card">
              <p class="card-title">2</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Внедрение</p>
              <p class="card-text">
                Бесшовно соединим решение с&nbsp;вашими системами. Настроим
                алгоритмы, роботов и&nbsp;процессы.
              </p>
            </div>
          </div>
          <div class="d-3 db11 mt6" v-onscroll="{ delay: 500 }">
            <div class="card">
              <p class="card-title">3</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Тестирование</p>
              <p class="card-text">
                Проверим процессы на&nbsp;небольшой группе сотрудников. Соберём
                обратную связь и&nbsp;внесём коррективы.
              </p>
            </div>
          </div>
          <div class="d-3 db11 mt6 mb7" v-onscroll="{ delay: 750 }">
            <div class="card">
              <p class="card-title">4</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Запуск</p>
              <p class="card-text">
                Масштабируем решение на&nbsp;всю компанию. Обучим сотрудников
                и&nbsp;обеспечим техподдержку.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section db15 mb10">
      <div class="container">
        <div class="row middle">
          <div class="d-6 d-offset-1 d-hide mb4">
            <img src="@/assets/pages/corporate-portal/pic-02.svg"
              data-copyright-source="https://storyset.com/illustration/design-stats/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-5" v-onscroll>
            <h2>Кроссплатфор&shy;менность</h2>
            <p>
              <strong>Онлайн-портал.</strong> Закрытый ресурс с&nbsp;доступом
              только для ваших сотрудников. Для начала работы нужны интернет
              и&nbsp;электронная подпись.
            </p>
            <p>
              <strong>Мобильное приложение.</strong> Простой и&nbsp;понятный
              интерфейс для бысрого подписания документов прямо
              со&nbsp;смартфона.
            </p>
          </div>
          <div class="d-6 d-offset-1 m-hide" v-onscroll>
            <img src="@/assets/pages/corporate-portal/pic-02.svg"
              data-copyright-source="https://storyset.com/illustration/design-stats/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>

        <div class="row middle dv15 mv10">
          <div class="d-5 mb4" v-onscroll>
            <img src="@/assets/pages/corporate-portal/pic-03.svg"
              data-copyright-source="https://storyset.com/illustration/agreement/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-6 d-offset-1" v-onscroll>
            <h2>Совместная работа с&nbsp;документами</h2>
            <p>
              <strong>Полная синхронизация.</strong> Все документы в&nbsp;одном
              месте без бумаги и&nbsp;дублирования файлов. Создавайте
              и&nbsp;редактируйте документы, настраивайте совместный доступ
              и&nbsp;обменивайтесь с&nbsp;коллегами.
            </p>
            <p>
              <strong>Лёгкое подписание.</strong> Выдавайте электронные подписи
              сотрудникам и&nbsp;верифицируйте документы в&nbsp;пару
              кликов&nbsp;&mdash; для этого не&nbsp;нужны специальные навыки.
            </p>
          </div>
        </div>

        <div class="row middle">
          <div class="d-6 d-offset-1 d-hide mb4">
            <img src="@/assets/pages/corporate-portal/pic-04.svg"
              data-copyright-source="https://storyset.com/illustration/landing-page/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-5" v-onscroll>
            <h2>Горизонтальные связи</h2>
            <p>
              <strong>Сообщества.</strong> Создавайте комьюнити внутри портала
              и&nbsp;формируйте открытую и&nbsp;дружелюбную среду. Помогайте
              коллегам из&nbsp;разных городов и&nbsp;стран обмениваться идеями
              и&nbsp;опытом, объединяться по&nbsp;интересам.
            </p>
            <p>
              <strong>Корпоративная база знаний.</strong> Упрощайте обучение
              команды за&nbsp;счёт единой базы для инструкций, кейсов
              и&nbsp;гайдов. Объединяйте коллективные знания, сохраняя
              их&nbsp;для других команд и&nbsp;будущих сотрудников.
            </p>
          </div>
          <div class="d-6 d-offset-1 m-hide" v-onscroll>
            <img src="@/assets/pages/corporate-portal/pic-04.svg"
              data-copyright-source="https://storyset.com/illustration/landing-page/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container db13 mb10">
        <div class="row">
          <div class="d-12 db7 mb6" v-onscroll>
            <h2>Почему WorkCat</h2>
          </div>
          <div class="d-5" v-onscroll="{ delay: 250 }">
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/growth.svg" />
              <h4 class="dt4 mt3 b2">Рост корпоративной культуры</h4>
              <div class="sign-descr">
                Повышайте лояльность сотрудников и&nbsp;увеличивайте открытость
                компании за&nbsp;счёт расширения горизонтальных связей.
              </div>
            </div>
          </div>

          <div class="d-6 d-offset-1" v-onscroll="{ delay: 250 }">
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/doc.svg" />
              <h4 class="dt4 mt3 b2">Меньше времени на&nbsp;согласования</h4>
              <div class="sign-descr">
                Снижайте уровень бюрократии в&nbsp;компании за&nbsp;счёт
                совместной работы над документами и&nbsp;проектами.
              </div>
            </div>
          </div>

          <div class="d-5" v-onscroll="{ delay: 500 }">
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/effective.svg" />
              <h4 class="dt4 mt3 b2">Увеличение эффективности команд</h4>
              <div class="sign-descr">
                Помогайте коллегам оставаться в&nbsp;курсе работы над проектами.
                Сохраняйте накопленный опыт для адаптации новых сотрудников.
              </div>
            </div>
          </div>

          <div class="d-6 d-offset-1" v-onscroll="{ delay: 500 }">
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/chat.svg" />
              <h4 class="dt4 mt3 b2">Прозрачная среда для работы</h4>
              <div class="sign-descr">
                Обменивайтесь результатами работы, делитесь
                событиями&nbsp;&mdash; это поможет сформировать открытую среду
                в&nbsp;компании.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-light">
      <div class="container" v-onscroll>
        <div class="row db7 mb6">
          <div class="d-offset-1 d-9 dt12 mt7 d-center">
            <h2>Получите персональную консультацию</h2>
            <p class="lg">
              Оставьте заявку, и&nbsp;мы&nbsp;расскажем подробнее
              о&nbsp;функционале, вариантах внедрения и&nbsp;кастомизации,
              проведём презентацию решения.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="d-offset-3 d-6 db12 mb7">
            <v-contact-form action="https://ediweb.com/subscribe/9040b663" :centered="true" :openForm="true" />
          </div>
        </div>
      </div>
    </div>

    <v-modal-wrapper name="contacts">
      <v-contact-form
        action="https://ediweb.com/subscribe/9040b663"
        title="Отправить запрос"
        description="Мы свяжемся с вами, проведём предварительную оценку
        и отправим вам индвидуальное предложение."
      >
      </v-contact-form>
    </v-modal-wrapper>
  </div>
</template>

<script>
const meta = {
  title: "WorkCat − автоматизация кадрового документооборота и HR-процессов",
  description: "Управление подписанием и маршрутизацией кадровых документов. Помощь в запуске проектов по КЭДО",
};

export default {
  name: "CorporatePortal",
  components: {
    "v-header": () => import("@/components/v-header.vue"),

    "v-modal-wrapper": () => import("@/components/v-modal-wrapper.vue"),
    "v-contact-form": () => import("@/components/v-contact-form.vue"),
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "description",
        content: meta.description,
      },
      {
        name: "og:description",
        content: meta.description,
      },
    ],
  },
  methods: {
    showModal() {
      this.$modal.show("contacts");
    },
  },
};
</script>
